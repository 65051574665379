import React from 'react'
import PropTypes from 'prop-types'

function Search({ handleSubmit, handleBatchSubmit_kv, handleBatchSubmit_do, handleUrlChange, handleMethod, handleJson, url, radioInput, json }) {
    var submitFunction = handleSubmit;
    return (
        <form
        name="search-form"
            onSubmit={submitFunction}>
            <span style={{fontWeight: 'bold'}}>Url:</span><br/>
            <input
                value={url}
                onInput={handleUrlChange}
                placeholder="Request Endpoint Here"
                size="102"
            /><br /><br />
            <span>
                <span style={{fontWeight: 'bold'}}>Method:</span><br />
                <label>
                    <input 
                    type="radio"
					name="method"
					value="GET"
                    checked={radioInput==='GET'}
                    onChange={handleMethod}/>
                    <span>Get</span>
                </label>
                 <label>
                    <input 
                    type="radio"
					name="method"
					value="POST"
                    checked={radioInput==='POST'}
                    onChange={handleMethod}/>
                    <span>Post</span>
                </label>
                 <label>
                    <input
                    type="radio"
					name="method"
					value="PUT"
                    checked={radioInput==='PUT'}
                    onChange={handleMethod}/>
                    <span>Put</span>
                </label>

                 <label>
                    <input
                    type="radio"
					name="method"
					value="DELETE"
                    checked={radioInput==='DELETE'}
                    onChange={handleMethod}/>
                    <span>Delete</span>
                </label>
            </span><br /><br />

            <label>
                <span style={{fontWeight: 'bold'}}>Raw JSON Request:</span><br />
                <textarea rows="10" cols="100"
                value={json}
                aria-label='json-request'
                onInput={handleJson}>
                </textarea>
            </label><br /><br />
            <button onClick={submitFunction = handleSubmit}>
                Submit Request
            </button><br /><br />
            <button onClick={submitFunction = handleBatchSubmit_kv} >
                Submit batch posts to kv worker
            </button>&nbsp;&nbsp;
            <button onClick={submitFunction = handleBatchSubmit_do} >
                Submit batch posts to durable object
            </button>
        </form>
    )
}

Search.propTypes = {
    handleSubmit: PropTypes.func.isRequired, 
    handleUrlChange: PropTypes.func.isRequired, 
    handleMethod: PropTypes.func.isRequired, 
    handleJson: PropTypes.func.isRequired, 
    url: PropTypes.string.isRequired, 
    radioInput: PropTypes.string.isRequired, 
    json: PropTypes.string.isRequired
}

export default Search


