import React, { Component } from 'react'
import Search from '../components/controls/Search';
import Results from '../components/display/Results';
import { Request } from '../services/request-utils';

export default class PostPage extends Component {
    state = {
        url: 'https://kv.iwowsoft.workers.dev/all',
        method: 'GET',
        req:'',
        results: [],
    }

    post100000 = async (url) => {
        if (this.isRunning) {
            alert(`Running...\nPosting record nbr: ${this.recordNbr}.\nTo url: ${url}.`);
            return;
        }

        let maxItteration = 15000;

        if (!confirm(`Post ${maxItteration} new records!\nTo url: ${url}.`))
            return;

        this.isRunning = true;
        this.recordNbr = 0;
        let results;

        for (let i = 0; i <= maxItteration; i++) {
            results = await Request("Post", url + i, `{"k${i}": "k${i} value"}`);
            this.recordNbr++;
        }

        this.isRunning = false;
    }

    handleBatchSubmit_kv = async (e) => {
        let url = "https://kv.iwowsoft.workers.dev/hovid/employee/";
        e.preventDefault();
        this.setState({ results: "" }, async () => {
              this.post100000(url);
            }
        );
    }

    handleBatchSubmit_do = async (e) => {
        let url = "https://do-client.iwowsoft.workers.dev/hovid/employee/";
        e.preventDefault();
        this.setState({ results: "" }, async () => {
              this.post100000(url);
            }
        );
    }

      handleSubmit = async (e) => {
          e.preventDefault();
          const results = await Request(this.state.method, this.state.url, this.state.req);

          this.setState({ results: "" },
              () => {
                this.setState({results})
              }
        );
     }
      handleUrlChange = async (e) => {
          this.setState({url: e.target.value})

     }
      handleMethod = async (e) => {
           this.setState({method: e.target.value})

     }
      handleJson = async (e) => {
           this.setState({req: e.target.value})

     }

    render() {
        const {url, method, req, results} =this.state;
        return (
            <div>
                <header>
                    <h1>Mini Postman</h1>
                    <h3>Get all key values</h3>
                    <p>https://kv.iwowsoft.workers.dev/all</p>
                    <h3>Create a new key value with post</h3>
                    <p>https://kv.iwowsoft.workers.dev/?security_token=16125252787azq<br />
                        Put the key and its value (Ex: {`{"k1": "k1 value"}`}) in "Raw JSON Request" text box.
                    </p>
                </header>
                <Search
                    handleSubmit={this.handleSubmit}
                    handleBatchSubmit_kv={this.handleBatchSubmit_kv}
                    handleBatchSubmit_do={this.handleBatchSubmit_do}
                    handleUrlChange={this.handleUrlChange}
                    handleMethod={this.handleMethod}
                    handleJson={this.handleJson}
                    url={url} 
                    radioInput={method} 
                    json={req}
                />
                <Results results={results}/>
                
            </div>
        )
    }
}

